"use server";

import { getRequestEvent } from "solid-js/web";
import {
  getBrand,
  getBrandExtraMetadata,
  getClientConfig,
  getCoinsSummary,
  getMinimalClientConfig,
  getUserProfile,
  simulate,
} from "../apis/server_apis";
import { SDKInitParams } from "../types/auth";
import {
  BrandExtraMetadata,
  DiscountDetailsItem,
  ClientMinimalConfigOld,
  SimulatedTransactionDetails,
  VoucherProduct,
} from "../types/brand";
import { ClientConfig } from "../types/client";
import { UserProfile } from "../types/user";
import { Mode } from "~/types";
import { CoinsSummaryResponse } from "../types/order";
import { redirect } from "@solidjs/router";
import { getStaticProxyJson } from "../static";
import { serverRepo } from "../apis/server_repo";

type FAQObject = {
  "@type": "Question";
  name: string;
  acceptedAnswer: {
    "@type": "Answer";
    text: string;
  };
};

type SEOSchema = {
  alternateName: string;
  sameAs: string[];
  keywords: string;
  disambiguatingDescription: string;
  faqObjs: FAQObject[];
};

type MetadataSEOSchema = Record<string, SEOSchema>;

export type BrandL2RouteData = {
  brand: VoucherProduct;
  brandExtraMetadata: BrandExtraMetadata;
  userProfile?: UserProfile;
  clientConfig?: ClientConfig | ClientMinimalConfigOld;
  simulate:
    | {
        initialSimulateTransactionDetails: SimulatedTransactionDetails;
      }
    | {
        initialSimulateError: string;
      };
  hubbleCoinRewards?: DiscountDetailsItem;
  sdkMetaData?: SDKMetaData;
  mode: Mode;
  isLoggedIn: boolean;
  coinsSummary?: CoinsSummaryResponse;
  clientId: string;
  metadataSEOSchema?: MetadataSEOSchema;
};

type SDKMetaData = {
  mode: Mode;
  sid: string | undefined;
};

export const getBrandL2RouteData = async (
  brandKey: string,
  sdkInitParams: Partial<SDKInitParams>
): Promise<BrandL2RouteData> => {
  const requestEvent = getRequestEvent();
  const mode = requestEvent?.locals.mode ?? "hubble";
  const isSessionPresent = requestEvent?.locals.sid;

  let clientConfig$P:
    | Promise<ClientConfig | ClientMinimalConfigOld>
    | undefined;
  let userProfile$P: Promise<UserProfile> | undefined;
  let coinsSummary$P: Promise<CoinsSummaryResponse> | undefined;

  if (sdkInitParams.clientId) {
    clientConfig$P = getClientConfig(sdkInitParams.clientId);
  } else if (getRequestEvent()?.locals.clientId && isSessionPresent) {
    clientConfig$P = getMinimalClientConfig(
      getRequestEvent()?.locals.clientId!
    );
    coinsSummary$P = getCoinsSummary();
  } else if (mode === "rnr" && !isSessionPresent) {
    throw redirect(`/logout`);
  }

  if (isSessionPresent) {
    userProfile$P = getUserProfile();
  }

  const brand$P = getBrand(brandKey);
  const brandExtraMetadata$P = getBrandExtraMetadata(brandKey);
  const metadataSEOSchema$P = getStaticProxyJson(
    "brandl2_metadata_schema.json"
  );

  const resolved = await Promise.all([
    brand$P,
    brandExtraMetadata$P,
    userProfile$P ?? Promise.resolve(undefined),
    clientConfig$P ?? Promise.resolve(undefined),
    coinsSummary$P ?? Promise.resolve(undefined),
    metadataSEOSchema$P,
  ]);

  const brand = resolved[0];
  const brandExtraMetadata = resolved[1];
  const userProfile = resolved[2];
  const clientConfig = resolved[3];
  const coinsSummary = resolved[4];
  const metadataSEOSchema = resolved[5];

  const isVariantBrand = brand.variantDetailedResponse != null;
  const isDenominationsBrand = brand.amountConditions.denominations != null;
  let initialSimulateTransactionDetails;
  let initialSimulateError;
  let hubbleCoinRewards;

  try {
    const simulateResponse = await serverRepo.simulate({
      ...(isVariantBrand
        ? defaultRequestForAVariantBrand(brand)
        : isDenominationsBrand
          ? defaultRequestForADenominationsBrand(brand)
          : {
              voucherProductId: brand.externalId,
              amount: brand.amountConditions.defaultAmount,
            }),
      consumeCoins: mode === "rnr",
    });

    initialSimulateTransactionDetails = simulateResponse.data.filter(
      (data) => data.paymentRoute != "WALLET"
    )[0];

    const { totalDiscount, coinDiscount } =
      initialSimulateTransactionDetails?.discountDetails;

    if (brand.rewardType === "POSTPAID") {
      hubbleCoinRewards = {
        amount: totalDiscount.amount - coinDiscount.amount,
        percentage: totalDiscount.percentage - coinDiscount.percentage,
      };
    }
  } catch (error: any) {
    initialSimulateError = error.message;
  }

  let routeData = {
    clientConfig,
    brand,
    brandExtraMetadata,
    userProfile,
    simulate: initialSimulateTransactionDetails
      ? { initialSimulateTransactionDetails }
      : { initialSimulateError },
    mode,
    isLoggedIn: getRequestEvent()?.locals.sid !== undefined,
    coinsSummary,
    clientId: requestEvent?.locals?.clientId,
    ...(hubbleCoinRewards && { hubbleCoinRewards }),
    metadataSEOSchema,
  } as BrandL2RouteData;

  if (sdkInitParams.clientId && sdkInitParams.clientSecret) {
    routeData = {
      ...routeData,
      mode: "sdk",
      sdkMetaData: {
        mode: "sdk",
        sid: getRequestEvent()?.locals.sid,
      },
    };
  }

  if (mode === "rnr") {
    routeData = {
      ...routeData,
      coinsSummary,
    };
  }

  return routeData;
};

function defaultRequestForAVariantBrand(brand?: VoucherProduct) {
  const defaultVariant =
    brand?.variantDetailedResponse?.find((variant) => variant.isDefault) ??
    brand?.variantDetailedResponse?.at(0);

  return {
    variantId: defaultVariant?.variantId ?? "",
    amount: defaultVariant?.amount ?? 1000,
    voucherProductId: brand?.externalId ?? "",
  };
}

function defaultRequestForADenominationsBrand(brand?: VoucherProduct) {
  const defaultDenomination =
    brand?.amountConditions.defaultDenominationDetails?.at(0)?.denomination ??
    brand?.amountConditions.denominations?.at(0) ??
    brand?.amountConditions.defaultAmount;
  return {
    amount: defaultDenomination ?? 1000,
    voucherProductId: brand?.externalId ?? "",
  };
}
